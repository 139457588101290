<template v-slot:main>
  <div class="flex">
    <div
      class="max-md:flex max-md:justify-center max-sm:flex max-sm:flex-col md:ml-auto md:text-right"
    >
      <div
        class="text-primary flex flex items-start text-[2.5rem] font-bold max-md:mr-10 sm:justify-center md:justify-end"
      >

        {{ $n(pricing.total_ttc, "decimal") }}
        <span class="text-[1.8rem]">€ <sup class="text-[12px]">TTC</sup></span>
      </div>
      <div v-if="pricing.total_ht">
Soit {{ $n(pricing.total_ht, "decimal") }} € HT
      </div>
      <div class="max-sm:flex max-sm:flex-col">
        <div class="text-primary font-medium text-[0.75]">Détail du prix</div>
        <div class="text-neutral-lighter">
          <div>
            Prise en charge TTC
    
            <span class="font-bold">
              {{ $n(pricing.pickup_fee, "currency") }} TTC soit 
              <span v-if="pricing.pickup_feewithouttax">
              {{ $n(pricing.pickup_feewithouttax, "currency") }} HT
              </span>
            </span>
          </div>
          <div>
            Prix kilométrique
            <span class="font-bold">{{
              $n(pricing.km_price, "currency")
            }} TTC soit 
            <span v-if="pricing.km_pricewithouttax">
            {{ $n(pricing.km_pricewithouttax, "currency") }} HT 
                </span>
            </span>
        
          </div>
       
           <div v-if="pricing.demarche_apmb" style="display:none">
         FORFAIT DEMARCHE
            <span class="font-bold">{{
              $n(pricing.demarche_mb, "currency")
            }}
            TTC soit 
            <span v-if="pricing.demarche_mbwithouttax">
            {{ $n(pricing.demarche_mbwithouttax, "currency") }} HT
            </span>
            </span>
          </div>
           <div v-if="pricing.demarche_apmb !== 0" style="display:none">
          Démarche après MB
            <span class="font-bold" v-if="pricing.demarche_apmb">{{
              $n(pricing.demarche_apmb, "currency")
            }} TTC soit {{ $n(pricing.demarche_apmbwithouttax, "currency") }} HT
            </span>
          </div>
       
          <div>
            Commission Funargos
            <span class="font-bold">{{
              $n(pricing.funargos_commission, "currency")
            }} TTC soit 
            <span v-if="pricing.funargos_commissionwithouttax">
            {{ $n(pricing.funargos_commissionwithouttax, "currency") }} HT </span>
            </span>
          </div>

          
        </div>
      </div>
    </div>
    
  </div>

</template>
<script setup>
import { useConfigStore } from "~/store/config";
const { env } = useConfigStore();
const props = defineProps({
  pricing: {
    type: Object,
    required: true,
  },
});
</script>
