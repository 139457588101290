<template>
  <div
    class="border-primary-lightest max:md:justify-start w-full rounded-2xl border-2 p-7 transition-colors md:px-16 md:py-14"
  >
    <div style="position:relative"
      class="text-primary mb-5 flex items-center justify-between uppercase"
      v-if="route?.departure_date"
    >
      <div>
        <icon icon="calendrier" class="mr-2 h-6 w-6"></icon>
        <span>
          {{
            formattedDate(route.departure_date, route.departure_time_of_day)
          }}</span
        >
      </div>

<div style="position:absolute;right:0" class="rating-stars" @click="togglePopup">
  <div
    v-for="n in 5"
    :key="n"
    class="star"
    :class="{'filled': n <= route.total_reviews}"
  ></div>
 
  <span class="review-count" v-if="route.total_reviews > 0">({{ route.total_reviews }})</span>
</div>

<!-- Popup for reviews -->

<div v-if="showPopup" class="popup" @click.self="hidePopup">
  <div class="popup-content">
    <span class="close" @click="hidePopup">&times;</span>
   <h1 style="margin-bottom:20px" class="text-primary text-[1.875rem] font-bold leading-[2.5rem]"><span class="bg-secondary mr-3 inline-block h-3 w-3 rounded-full"></span>
Voir les avis
    <span class="bg-ternary ml-3 inline-block h-3 w-3 rounded-full"></span></h1>
    <div v-for="(review, index) in route.reviews" :key="review.user_name" class="review">
      <div class="rating-stars">
        <div
          v-for="n in 5"
          :key="n"
          class="star"
          :class="{'filled': n <= review.review}"
        ></div>
      </div>
      <p class="user-name"><strong>{{ review.user_name }}</strong></p>
 
      <div v-if="index < route.reviews.length - 1" class="review-border"></div>
    </div>
  </div>
</div>

      <div class="flex items-center">
        <slot name="actions" />
      </div>
    </div>

    <div
      class="flex flex-col justify-between gap-3 border-b-2 border-dotted pb-5 lg:flex-row"
    >
  
      <div class="text-primary flex flex-col">
        <div class="flex min-h-[4rem]">
          <div class="mr-3 flex flex-col items-center py-2">
            <div class="bg-secondary h-4 w-4 rounded-full"></div>
            <div
              class="border-primary-lighter grow border-l-4 border-dotted border-opacity-60"
            ></div>
            <div class="bg-ternary h-4 w-4 rounded-full"></div>
          </div>
          <div
            class="flex flex-col justify-between whitespace-nowrap text-[1.5rem] font-semibold leading-[1.5rem]"
          >
            <div>
              <slot name="origin-name">{{ route?.origin_city?.name }}</slot>
            </div>

            <div>
              <slot name="destination-name">{{
                route?.destination_city?.name
              }}</slot>
            </div>
          </div>
        </div>
      </div>
      <div class="max-md:py-3">
        <slot name="main"></slot>
      </div>
    </div>
    <div class="pt-4">
      <slot name="footer" />
    </div>
  </div>
</template>
<script setup>
import { computed, defineProps } from "vue";
import InputSwitch from "primevue/inputswitch";

const dayjs = useDayjs();
const showPopup = ref(false);
const { $api } = useNuxtApp();
const checked = ref(false);

const props = defineProps({
  route: {
    type: Object,
    required: true,
  },
});

const computedDistance = computed(() => {
  return route.value.distance / 1000;
});

const togglePopup = () => {
  showPopup.value = !showPopup.value;
};

const hidePopup = () => {
  showPopup.value = false;
};

const formattedDate = (departureDate, departureTimeOfDay) => {
  departureTimeOfDay =
    departureTimeOfDay === "morning"
      ? "au matin (00h00 à 11h59)"
      : "après-midi (12h00 à 23h59)";

  return (
    "Départ le " +
    dayjs(departureDate).format("DD/MM/YYYY") +
    " " +
    departureTimeOfDay
  );
};
</script>


<style scoped>
.rating-stars {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.star {
  width: 24px;
  height: 24px;
  background-color: #d3d3d3; /* Light gray for empty star */
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  margin-right: 2px; /* Space between stars */
}

.star.filled {
  background-color: #ffd700; /* Gold for filled star */
}

.review-count {
  margin-left: 8px;
  font-size: 14px;
  color: #333; /* Adjust color as needed */
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index:9999999;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto; /* Scroll if content is too long */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  max-width: 90%;
  max-height: 80%;
  overflow-y: auto; /* Scroll if content is too long */
  width: 500px; /* Adjust width as needed */
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 18px;
}

.popup-heading {
  text-align: left;
  margin-bottom: 15px;
  font-size: 20px;
  font-weight: bold;
}

.rating-stars {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.star {
  width: 24px;
  height: 24px;
  background-color: #d3d3d3; /* Light gray for empty star */
  clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
  margin-right: 2px; /* Space between stars */
}

.star.filled {
  background-color: #ffd700; /* Gold for filled star */
}

.user-name {
  margin-top: 5px;
  font-weight: bold;
}

.review-text {
  margin-top: 5px;
}

.review-border {
  border-bottom: 1px solid #ddd;
  margin: 10px 0;
}

.review:last-child .review-border {
  display: none; /* No border for the last review */
}
.norat .rating-stars {
    display: none;
}
.yesrat .rating-stars{
  display:none;
}
.popup{
  display:none;
}
.yesrat .popup{
  display:flex;
}
</style>